<template>
  <div class="page padding-left-large layout-vertical">
    <div class="layout-horizontal layout-middle layout-inflexible padding-vertical">
      <back-button @click="$router.back()" class="font-size-extra-large"></back-button>
      <ys-input
          class="input-search"
          prefix-icon="el-icon-search"
          placeholder="搜索食谱"
          size="large"
          v-model="search"
          @input="startSearch"
      ></ys-input>
      <ys-button
          class="margin-left"
          lighting
          @click="clickAdd"
      >+ 添加食谱
      </ys-button>
    </div>
    <div class="layout-vertical layout-flexible" style="overflow: hidden">
      <ys-infinite-scroll-view style="height: 100%" @loadmore="loadMore">
        <div
            class="list-item padding-horizontal-large padding-vertical layout-horizontal layout-middle font-size-medium"
            v-for="cookeryBook in cookeryBookList"
            :key="cookeryBook.id"
            @click="clickCookeryBook(cookeryBook)"
        >
          <span class="fas fa-utensils layout-inflexible"></span>
          <div class="margin-left layout-flexible" style="overflow: hidden;">
            <div>{{ cookeryBook.name }}</div>
            <div class="font-size-small font-color-placeholder font-nowrap" style="width: 100%;"
                 v-if="cookeryBook.message">{{ cookeryBook.message }}
            </div>
          </div>
          <div class="margin-left layout-inflexible">
            <ys-button
                class="btn-delete"
                round lighting type="secondary"
                @click.stop="clickDeleteCookeryBook(cookeryBook)"
            >
              <span class="fas fa-trash-alt font-color-danger"></span>
            </ys-button>
          </div>

        </div>
      </ys-infinite-scroll-view>
    </div>
    <delete-dialog
        :visible.sync="deleteRemindDialogVisible"
        title="删除食谱"
        message="您确定要删除该食谱吗?"
        @confirm="confirmDeleteCookeryBook"
    ></delete-dialog>
  </div>
</template>

<script>
import YsButton from "@/components/wedigets/YsButton";
import YsInput from "@/components/wedigets/YsInput";
import httpapi from "@/assets/javascript/httpapi";
import BackButton from "@/components/backbutton/BackButton";
import YsInfiniteScrollView from "@/components/wedigets/YsInfiniteScrollView";
import DeleteDialog from "@/components/dialog/DeleteDialog";

export default {
  name: "CookeryBookList",
  mixins: [httpapi],
  components: {DeleteDialog, YsInfiniteScrollView, BackButton, YsInput, YsButton},
  props: {
    organizationId: Number,
  },
  data() {
    return {

      /**
       * api同步参数
       */
      busyLoadingCode: 0,

      /**
       * 搜索条件
       */
      search: null,

      /**
       * 食谱列表
       */
      cookeryBookList: [],
      pageNum: 1,
      totalPages: 0,
      pageSize: 20,

      searchTimeoutId: null,

      /**
       * 删除食谱
       */
      deletingCookeryBook: null,
      deleteRemindDialogVisible: false,
    }
  },
  watch: {
    organizationId: {
      handler: function () {
        this.loadingCode++;

        this.search = null;
        this.cookeryBookList = [];
        this.pageNum = 1;
        this.totalPages = 0;
        if (this.searchTimeoutId) {
          clearTimeout(this.searchTimeoutId);
          this.searchTimeoutId = null;
        }
        if (this.organizationId) {
          this.loadCookeryBookList();
        }
      },
      immediate: true,

    }
  },
  mounted() {
    window.eventBus.$on('update:cookery_book', this.handleCookeryBookUpdate)
  },
  beforeDestroy() {
    window.eventBus.$off('update:cookery_book', this.handleCookeryBookUpdate)
  },
  activated() {
    this.search = null;
  },
  methods: {
    clickAdd() {
      this.$router.push({name: 'cookery_book_diet_list'});
    },
    clickCookeryBook(cookeryBook) {
      this.$router.push({name: 'cookery_book_diet_list', query: {id: cookeryBook.id}});
    },
    clickDeleteCookeryBook(cookeryBook) {
      this.deletingCookeryBook = cookeryBook;
      this.deleteRemindDialogVisible = true;
    },
    confirmDeleteCookeryBook() {
      this.removeCookeryBook(this.deletingCookeryBook.id);
      this.deletingCookeryBook = null;
    },
    handleCookeryBookUpdate(event) {
      let id = event.id;
      this.loadCookeryBook(id);
    },
    isBusy() {
      return this.busyLoadingCode === this.loadingCode;
    },
    startSearch: function () {
      if (this.searchTimeoutId) {
        clearTimeout(this.searchTimeoutId);
      }
      this.searchTimeoutId = setTimeout(() => {
        this.loadCookeryBookList();
        this.searchTimeoutId = null;
      })
    },
    loadCookeryBookList(pageNum = 1) {
      if (this.isBusy()) return;
      this.busyLoadingCode = this.loadingCode;

      let search = this.search;
      let path = `/cookery_book/list/${this.organizationId}/${pageNum}/${this.pageSize}`;
      if (search) {
        path += `/${search}`
      }

      this.$reqGet({
        path,
      })
          .returnWhenCodeMatches()
          .then(res => {
            let cookeryBookList = pageNum === 1 ? [] : this.cookeryBookList;
            this.$appendAfter(cookeryBookList, res.data.list);
            this.pageNum = res.data.pageNum;
            this.totalPages = res.data.pages;
            this.cookeryBookList = cookeryBookList;
          })
          .catch(() => this.$message.error('加载失败'))
          .complete(() => this.busyLoadingCode = 0);
    },
    loadMore() {
      if (this.pageNum < this.totalPages) this.loadCookeryBookList(this.pageNum + 1);
    },
    loadCookeryBook(id) {
      this.$reqGet({
        path: `/cookery_book/${id}`
      })
          .returnWhenCodeMatches()
          .then(res => {
            let cookeryBook = res.data;
            this.$appendBefore(this.cookeryBookList, [cookeryBook]);
          })
          .catch(() => this.$message.error('加载失败'));
    },
    removeCookeryBook(id) {
      if (this.isBusy()) {
        this.$showBusy();
        return;
      }
      this.busyLoadingCode = this.loadingCode;
      this.$reqDelete({
        path: `/cookery_book/${id}`
      })
          .returnWhenCodeMatches()
          .then(() => {
            this.$message.success('删除成功');
            for (let n = this.cookeryBookList.length - 1; n >= 0; n--) {
              if (this.cookeryBookList[n].id === id) {
                this.cookeryBookList.splice(n, 1);
                break;
              }
            }
          })
          .catch(() => this.$message.error('删除失败'))
          .complete(() => this.busyLoadingCode = 0);
    }
  },
}
</script>

<style scoped>

.list-item {
  overflow: hidden;
}

.list-item .btn-delete {
  visibility: hidden;
}

.list-item:hover .btn-delete {
  visibility: visible;
}

.page {
  height: 100vh;
}

.input-search {
  width: 300px;
}

</style>